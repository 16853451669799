html {
    padding:0;
    margin:0;
    font-family: monospace;
}
body {
    margin:0;
    padding:0;
}

a {
    color:inherit;
    text-decoration: none;
}
a:visited{
    color:inherit;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background:#d5d5da;
}